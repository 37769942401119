/* Header Container */
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: transparent;
  z-index: 10;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
}

/* Change background to white on hover */
.header:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

/* Logo Section */
.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 50px;
  margin-right: 10px;
}

.logo-text {
  font-size: 20px;
  font-weight: bold;
  color: white;
  transition: color 0.3s ease;
}

.header:hover .logo-text {
  color: #333;
}

/* Navigation Menu */
.nav-menu {
  display: flex;
  align-items: center;
  gap: 20px;
}

.nav-link {
  text-decoration: none;
  color: white;
  font-size: 16px;
  position: relative;
  transition: color 0.3s ease;
}

.header:hover .nav-link {
  color: #333;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #007bff;
  transition: width 0.3s ease;
}

.nav-link:hover {
  color: #007bff;
}

.nav-link:hover::after {
  width: 100%;
}

/* Dropdown Menu */
.nav-dropdown {
  position: relative;
}

.nav-dropdown .nav-link {
  position: relative;
  z-index: 2;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1;
  min-width: 150px;
}

.nav-dropdown .nav-link:hover::after {
  width: 100%; /* Full underline on hover */
}

.nav-dropdown .nav-link::after {
  content: '';
  position: absolute;
  bottom: -3px; /* Space between text and underline */
  left: 0;
  width: 0;
  height: 2px;
  background-color: #007bff;
  z-index: 3; /* Ensure underline stays above dropdown */
  transition: width 0.3s ease;
}

.nav-dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  display: block;
  padding: 5px 10px;
  color: #333;
  text-decoration: none;
  font-size: 14px;
}

.dropdown-content a:hover {
  background-color: #f0f0f0;
  color: #007bff;
}

/* Profile and Cart Buttons */
.header-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cart-container {
  position: relative;
}

/* Cart Hover */
.cart-hover {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 300px;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.cart-container:hover .cart-hover {
  opacity: 1;
  visibility: visible;
}

.cart-hover h3 {
  margin: 0;
  padding: 0 0 10px;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  font-weight: bold;
}

.cart-hover ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.cart-hover ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  font-size: 14px;
}

.cart-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.view-cart-button {
  font-size: 14px;
  color: #007bff;
  cursor: pointer;
}

.view-cart-button:hover {
  text-decoration: underline;
}

.clear-cart-icon {
  font-size: 18px;
  color: #ff4d4f;
  cursor: pointer;
}

.clear-cart-icon:hover {
  color: #d9363e;
}

@media (max-width: 768px) {
  .nav-menu {
    display: none;
  }

  .nav-menu.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px;
    width: 200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .nav-link {
    color: #333;
    margin: 10px 0;
  }

  .nav-link:hover {
    color: #007bff;
  }
}
